import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions'
import { ModalButton } from '../../../components/ModalButton'
import { Modal } from '../../../components/Modal'
import { ScrollListView } from '../../../components/ScrollListView'
import { GranitePreviewCard } from './GranitePreviewCard'
import { GraniteCarousel } from './GraniteCarousel'
import { FgraniteColors } from '../../family/FgraniteColors'
import * as _ from 'lodash'
//import eventBus from '../../../services/EventBus'
//import ElemConfirm from '../../../components/ElemConfirm'
import * as apiService from "../../../services/api.service";
import {userCan, getOrganization} from '../../../services/userRights.service';
import {defaultBicolorGranites, removeDuplicates} from '../../../services/utils.service'
import cn from 'classnames';
import { Modal as ConfirmModal } from 'reactstrap'
import { isConfigRequested } from "../../../services/configurationWarningChecks"
import { gtmFamily } from '../../../services/gtmFamily';
import { TranslationContext } from "../../../context/TranslationContext";

/**
 * ModalButton permettant de choisir le granite pour tout le monument
 */
class GlobalGranitePickerComponent extends Component {
  static contextType = TranslationContext;
  state = {
    selectedIndex: 0,
    pictos: [],
    currentFilter: null,
    currentFilterSearch: null,
    showConfirm: false,
    fromMatchingColor: false,
    selectedGranite: null,
    defaultgranites: [],
    maxgranites: 0,
    _granite: {},
    _isMounted: false,
    isallgranites: false,
    filter_list: [],
    color: ""
  }

  modal
  granites = this.props.granites.map((r) => ({
    ...r,
    showConfirm: false
  }))
  
  componentDidMount = (data) => {
    document.querySelector("body").addEventListener("click", this.handleClick,false)
    this.setState({ _isMounted: true })
    if(userCan.granitePicto()) { 
      if(!this.props.family){
        apiService.getExternal(`granitePictos`).then(granitepic=>{
          this.setState({ pictos: granitepic})
        })
      }
    }
    // GTM
    if(this.props.family) {
      const allGranites = _.filter(this.props.granites, 'available')
        .map(g => {
          return {
            reference: g.reference,
            name: g.name,
            price: g.price,
            origin: g.origin,
            color: g.colorGranite
          }
        })
      gtmFamily().customizeConfigurator(
        "configurator.filter",
        "liste granits",
        {
          type: "granit",
          query: "couleur",
          filter: "Toutes les couleurs"
        },
        allGranites
      )
    }
    // end GTM
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // GTM
    if ( this.props.family && this.props.filter !== nextProps.filter) {
      const { filter, granites, configuration } = nextProps
      const { monument } = configuration

      let resGranites = _.filter(granites, 'available')
      resGranites = resGranites
        .filter( g => {
          if (filter == null) {
            return true
          }
          return g.reference === monument.graniteMain || g.colorGranite === filter 
        })
        .map(g => {
          return {
            reference: g.reference,
            name: g.name,
            price: g.price,
            origin: g.origin,
            color: g.colorGranite
          }
        })
      gtmFamily().customizeConfigurator(
        "configurator.filter",
        "liste granits",
        {
          type: "granit",
          query: "couleur",
          filter: filter == null ? "Toutes les couleurs" : filter
        },
        resGranites
      )
    }
    // end GTM
  }

  componentWillUnmount() {
    document.querySelector("body").removeEventListener("click", this.handleClick, false)
    this.setState = (state, callback) => {
      return
    }
  }
  
  handleClick = (e) => {
    if (!e.target.classList.contains("colorFilter") && !e.target.classList.contains("productColorFilter")) {
      if (document.getElementById("matchingColors")) document.getElementById("matchingColors").style.display = "none"
    }
  }

  onClickDisableGranite = (granite, response) => {
    const index = this.granites.findIndex(
      (r) => r.reference === granite.reference
    )
    if (!this.granites[index].showConfirm) {
      this.granites[index].showConfirm = true
      this.setState({ showConfirm: true, _granite: this.granites[index] })
    } else if ('oui' === response) {
      this.props.actions.removeAllAccessories()
      this.onSelectGranite(granite)
      this.granites[index].showConfirm = false
      this.setState({ showConfirm: false })
    } else {
      this.granites[index].showConfirm = false
      this.setState({ showConfirm: false })
    }
  }

  onSelectGranite = (granite, index) => {
    const graniteSecondary = granite.gs ? granite.gs.reference : null
    if (this.props.onSelect) {
      this.props.onSelect(granite)
    } else {
      this.props.actions.updateGraniteGlobal(granite.reference,graniteSecondary)
      this.setState({ selectedIndex: index })
      this.modal.close()
    }
  }
  findGraniteIndex = (graniteRef, granites) => {
    return granites.findIndex((elt) => elt.reference === graniteRef)
  }

  handlePlusClick = () =>{
    this.setState({isallgranites: true});
    const { currentFilter } = this.state
    const filter = currentFilter || this.props.filter
    let filters = [];
    if(!this.state.filter_list.includes(filter)){
      filters = this.state.filter_list
      filters.push(filter)
      this.setState({ filter_list: filters})
    }
  }

  onModalClose = () =>{
    this.setState({ currentFilter: null, currentFilterSearch: null, selectedGranite: null });
  }

  renderItem =
    (disabledGranitesByAccessories, availableGranitesByHeadstone = null, t=null) =>
    (granite, index) => {
      const { monument, frame, bicolorMonument } = this.props.configuration
      if(granite && granite.reference === 0 && !this.props.family){
        return (
          <div className={cn(["GraniteCard", "GranitePreviewCard"])} style={{background:"#7f7f7f"}} onClick={()=>this.handlePlusClick()}>
            <figure className="LazyImage"></figure>
            <div className="Infos plusGranite"><div><div className="plus">+</div><div>Voir plus de granits</div></div></div>
          </div>
        );
      }
      const selectedGranite = bicolorMonument && !this.props.family ? (granite && (granite.reference === monument.graniteMain && granite.gs.reference === monument.graniteSecondary) && index === 0) : (granite && granite.reference === monument.graniteMain)
      return (granite && 
        <GranitePreviewCard
          key={granite?.reference}
          monument={monument}
          pictos={this.state.pictos && this.state.pictos.filter(picto => granite.pictos && granite.pictos.split(':').includes(picto.id.toString()))}
          granite={granite}
          frameMode={frame && frame.mode}
          pattern={true}
          selected={selectedGranite}
          disabledAccessory={
            disabledGranitesByAccessories.indexOf(granite.reference) >= 0
          }
          disabledHeadstone={
            availableGranitesByHeadstone &&
            availableGranitesByHeadstone.indexOf(granite.reference) < 0
          }
          onSelectGranite={() => this.onSelectGranite(granite, index)}
          price={this.props.price}
          config={this.props.configuration}
          hoverInfo={this.props.hoverInfo}
          onClickDisableGranite={() =>
            this.onClickDisableGranite(granite, 'oui')
          }
          fromFamily={this.props.family}
          color={this.state.color}
        />
      )
    }

  render() {
    const t = this.context;
    const { granites, matchcolors, configuration, accessories, headstones, filter, family, isFamily } = this.props;
    const { monument,bicolorMonument } = configuration
    let hideGranites = []

    // let availableGranites = _.filter(granites, 'available')
    let availableGranites = _.filter(granites, 'available').sort((a, b) => b.poids - a.poids).map((granite) => {
      const modifiedName = !isFamily ? t(`config3d_granit_${granite.reference}`) : granite.name
      return { ...granite, name: modifiedName };
    });
    if (isFamily && bicolorMonument) {
      availableGranites = availableGranites.filter((granite) => granite.gs && granite.gs.reference === monument.graniteSecondary)
    }
    
    availableGranites = _.filter(
      availableGranites,
      graniteRef => {
        let graniteList = graniteRef.catalogs && graniteRef.catalogs.split(/[;,]/) || []
        const organizationCatalog = getOrganization().catalogs && getOrganization().catalogs.split(/[;,]/) || []
        if(organizationCatalog.length === 0 || graniteList.length === 0 ) return false 
        for(let i=0; i<graniteList.length; i++) {
          if(organizationCatalog.indexOf(graniteList[i]) !== -1) return true
        }
        return false

      }

    )

    // Available Granites Names to filter
    const availableGranitesNames =  availableGranites
    .filter((a, i) => availableGranites.findIndex(b => b.reference === a.reference) === i)
    .map((granite, index)  => ({ id:index, name:(granite.name.toUpperCase()), name2:(granite.name.toUpperCase().replace(/É|Ê|È|Ë/g, "E")) }));
    availableGranitesNames.sort(function(a, b){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    });
    
    const maxWeight = availableGranites[0] ? availableGranites[0].maxpoids : 0 ;

    const theFilter = filter
      ? filter
      : this.state.currentFilter
      ? this.state.currentFilter
      : null
    if (theFilter) {
      availableGranites = availableGranites.filter(
        (granite) => {
          if (bicolorMonument) {
            const filter = !isFamily && !theFilter.includes("/") ? `${theFilter}/${theFilter}` : theFilter
            if (!isFamily) {
              if (this.state.fromMatchingColor) {
                return granite.colorGranite.includes(filter) && granite.reference === granite.gs.reference
              } else {
                return granite.colorGranite.includes(filter)
              }
            } else {
              return  granite.colorGranite.includes(`${theFilter}/`)
            }
          } else  {
            return granite.colorGranite === theFilter
          }
        }
      )
    }
    const theFilterSearch = this.state.currentFilterSearch
      ? this.state.currentFilterSearch
      : null
      if (theFilterSearch) {
        if(bicolorMonument && !isFamily) {
          availableGranites = availableGranites.filter(
            (granite) => {
              const graniteMain = granite.name.toUpperCase().includes(theFilterSearch.toUpperCase()) || granite.name.toUpperCase().replace(/É|Ê|È|Ë/g, "E").includes(theFilterSearch.toUpperCase())
              const graniteSecondary = granite.gs.name.toUpperCase().includes(theFilterSearch.toUpperCase()) || granite.gs.name.toUpperCase().replace(/É|Ê|È|Ë/g, "E").includes(theFilterSearch.toUpperCase())
              return graniteMain || graniteSecondary
            }
          )
        } else {
          availableGranites = availableGranites.filter(
            (granite) => granite.name.toUpperCase().includes(theFilterSearch.toUpperCase()) || granite.name.toUpperCase().replace(/É|Ê|È|Ë/g, "E").includes(theFilterSearch.toUpperCase())
          )
        }
      }
    const { adminConfig } = this.props
    if (
      adminConfig &&
      adminConfig.granitesToHide &&
      adminConfig.granitesToHide.length >= 0
    ) {
      hideGranites = adminConfig.granitesToHide.map((granite) => granite.value)
      availableGranites = availableGranites.filter(
        (granite) =>
          !hideGranites.some((hideGranite) => hideGranite === granite.reference)
      )
    }
    // const monumentGranite = _.find(granites, {
    //   reference: monument.graniteMain
    // })

    let monumentGranite = granites.find(granite => granite.reference === monument.graniteMain)
    if (bicolorMonument) {
      monumentGranite = granites.find(granite => granite.reference === monument.graniteMain && granite.gs.reference === monument.graniteSecondary)
    }
    if (monumentGranite) {
      monumentGranite.name = !isFamily ? t(`config3d_granit_${monumentGranite.reference}`) : monumentGranite.name
    }

    const isMonumentMadeInFrance =
      monumentGranite && monumentGranite.madeInFrance
    let disabledGranitesByAccessories = []
    if (configuration.accessories.length) {
      // On test si au moins un accessoire possède un granit différent du monument.
      // Cependant, on ne peut pas distinguer le cas où NF a été positionné en fallback ou à la main
      const atLeastOneAccessoryWithDifferentGranite = _.filter(
        configuration.accessories,
        (accessory) => {
          let g = granites.find(gr => gr.reference === accessory.granite)
          if (g && g.madeInFranceAndImported) {
            return false
          }
          return accessory.granite && accessory.granite !== monument.graniteMain
        }
      ).length

      // Si l'accessoire peut être frabriqué en france ou import
      const accessoriesWithoutFFIM =_.filter(accessories, a => {
        if (a.granites){
          let gdefault = a.granites.find(gr => gr.id === a.granite)
          if(gdefault && gdefault.madeInFranceAndImported) {
            return false
          }
        }
        return true
      })

      // Pour ne pas calculer cette intersection pour chaque granit, on la construit la première fois puis on la conserve.
      let granitesAvailableOnAllAccessories
      disabledGranitesByAccessories = _.chain(availableGranites)
        .filter(({ reference, madeInFrance }) => {
          // FR  => FR  : il faut vérifier que les accessoires sans granite sont compatibles avec ce granit
          // FR  => NFR : Si au moins un accessoire a un granit, on bloque sinon ok car fallback sur NF.
          // NFR => NFR : OK (fallback sur NF)
          // NFR => FR  : Si au moins un accessoire a un granit, on bloque.
          //              Sinon on vérifie que tous les accessoires sont compatibles avec ce granit

          if (accessoriesWithoutFFIM.length === 0) {
            // Si l'accessoire peut être fabriqué en france ou import
            return false
          }

          if (
            madeInFrance !== isMonumentMadeInFrance &&
            atLeastOneAccessoryWithDifferentGranite
          ) {
            // Si on passe de FR à non FR ou inversement et qu'au moins un accessoire
            // a un granite de spécifié (ie différent du monument), on bloque ce granit
            return true
          }

          if (!madeInFrance) {
            // C'est un granit non FR. Au pire, s'il n'est pas compatible avec l'un des accessoires,
            // on fallback sur Noir fin.
            return false
          }

          if (!granitesAvailableOnAllAccessories) {
            granitesAvailableOnAllAccessories = _.intersection(
              ..._.chain(accessoriesWithoutFFIM)
                .map('granites')
                .map((granites) => _.map(granites, 'reference'))
                .value()
            )
          }
          return granitesAvailableOnAllAccessories.indexOf(reference) < 0
        })
        .map('reference')
        .value()
    }
    //Place main granite on top
    // !(bicolorMonument && (theFilter || theFilterSearch))
    if (!(bicolorMonument && theFilter)) {
      availableGranites = availableGranites.filter((elt) => {
        const graniteMain = elt.reference !== monument.graniteMain
        return  bicolorMonument && !isFamily ? 
        graniteMain && elt.reference !== monument.graniteSecondary : graniteMain
      })
    }
    
    if (theFilter && !isFamily) {
      availableGranites = availableGranites
                            .sort((g1,g2) => g1.gs ? g1.gs.colororder - g2.gs.colororder : true)
                            .sort((g1,g2) => g1.colororder - g2.colororder)
    } else if(bicolorMonument && !isFamily && !theFilterSearch) {
      if(this.state.defaultgranites.length > 0){
        availableGranites = this.state.defaultgranites.filter(granite => !(granite.reference === monumentGranite.reference && granite.gs.reference === monumentGranite.gs.reference))
                                                      .slice(0,74)
      } else {
        this.setState({ maxgranites: availableGranites.length});
        availableGranites = defaultBicolorGranites(availableGranites, matchcolors)
        this.setState({ defaultgranites: availableGranites});
      }
    }
    availableGranites.unshift(monumentGranite)
    const availableGranitesByHeadstone =
      headstones && headstones.length
        ? _.map(
            (
              _.find(headstones, { reference: monument.headstone.reference }) ||
              {}
            ).granites,
            'reference'
          )
        : null
    
    const overlayClass =
      this.props.configuration.configType === 'family'
        ? 'CatalogModalOverlay Family'
        : 'CatalogModalOverlay'
    const { currentFilter, currentFilterSearch, fromMatchingColor, selectedGranite } = this.state
    let addPlus = true;
    let availableGranitesLength;
    const filterCurrent = currentFilter || this.props.filter
    // const filterCurrentSearch = currentFilterSearch || this.props.filter

    // && theFilter === null
    if(!this.state.currentFilterSearch && !family){
      if(!this.state.filter_list.includes(filterCurrent)){
        addPlus = false;
        if(availableGranites.length > availableGranites.filter(granite => granite && granite.poids ? granite.poids <= granite.maxpoids : true).length && (!this.state.isallgranites || !addPlus)){
          availableGranitesLength = availableGranites.filter(granite => granite && granite.poids <= granite.maxpoids).length;
          availableGranites = availableGranites.filter(granite=> granite && 
                              !hideGranites.some(hideGranite=>hideGranite === granite.reference)  
                              && granite.poids <= granite.maxpoids 
                              && granite.reference !== monument.graniteMain)
          availableGranites.push({reference: 0,poids: 0,maxpoids: maxWeight})
          availableGranites.unshift(monumentGranite)
    
        }
      }
    }
    const displayMessage = bicolorMonument && !family && !theFilter && !theFilterSearch && this.state.maxgranites > 75
    let buttonContent = !isFamily ? t("config3d_granit_button_monument") : "Monument";
    const seeMoreCombinations = !isFamily ? t("productmodal_multigranit_bicolor-see-more-combinations") : ""
    let buttontitle = !isFamily ? t("config3d_granit_modal_monument_title") : "Choisissez un granit à appliquer sur tout le monument"
    let buttonIcon = "monument3-icon monuments-icon"

    if (monument.category && monument.category === "SEM&PLT") {
      buttonContent = !isFamily ? t("config3d_actionbuttons_granittoggle") : "Granits"
    }
    else if (monument.category && monument.category.includes("SEM")) {
      buttontitle = !isFamily ? t("config3d_granit_modal_frame_title") : "Choisissez un granit à appliquer sur la semelle"
      buttonIcon = "frame-icon"
      buttonContent = !isFamily ? t("config3d_granit_button_frame") : "Semelle"
    } else if (monument.category && monument.category.includes("PLT")) {
      buttontitle = !isFamily ? t("config3d_granit_modal_veneer_title") : "Choisissez un granit à appliquer sur le placage"
      buttonIcon = "veneer-icon"
      buttonContent = !isFamily ? t("config3d_granit_button_veneer") : "Placage"
    }

    return (
      <div className='MainButton'>
        <ModalButton
          ref={(_modal) => (this.modal = _modal)}
          buttonContent={buttonContent}
          openAtStart={this.props.isSmartphone}
          noModal={this.props.noModal}
          buttonIcon={buttonIcon}
          onModalClose={this.onModalClose}
          disabled={isConfigRequested()}
          buttonClassName="btn-config-secondary config-button-background text-dark config-button-hover"
          className="buttonModal background-page"
          toggleSteps={this.props.toggleSteps}
          launchIntro={this.props.launchIntro}
          madalName={this.props.madalName}
        >
          <div className='CompleteGranitePicker'>
            {!this.props.noModal && (
              <div className='Header'>
                <div className='HeaderContainer'>
                  <h1 className='Headline'>
                    { buttontitle }
                  </h1>
                </div>
              </div>
            )}
            {!family && (
              <FgraniteColors
                filter={(value,value2, value3, value4) => this.setState({ currentFilter: value, currentFilterSearch: value2, fromMatchingColor: value3, selectedGranite: value4 })}
                family={this.props.family}
                currentColor={currentFilter}
                currentSearch={currentFilterSearch}
                selectedGranite = {selectedGranite}
                fromMatchingColor={fromMatchingColor}
                hideTitle={true}
                items={availableGranitesNames}
              />
            )}
            {!addPlus &&
                <ScrollListView
                  items={availableGranites}
                  renderItem={this.renderItem(
                    disabledGranitesByAccessories,
                    availableGranitesByHeadstone
                  )}
                  className='GlobalGranitePicker'
                  seeMore = {displayMessage}
                  seeMoreCombinations = {seeMoreCombinations}
                />
              }
            {addPlus &&
              
              <ScrollListView
                items={availableGranites.filter(
                  (granite) =>
                    !hideGranites.some(
                      (hideGranite) => hideGranite === granite.reference
                    )
                )}
                renderItem={this.renderItem(
                  disabledGranitesByAccessories,
                  availableGranitesByHeadstone
                )}
                className='GlobalGranitePicker'
                seeMore = {displayMessage}
                seeMoreCombinations = {seeMoreCombinations}
              />
              
            }
            {this.props.carousel && (
              <Modal
                isOpen={
                  this.props.selected !== false &&
                  this.props.selected !== undefined &&
                  this.props.configuration.designType === 'A'
                }
                onRequestClose={this.props.closeModal}
                overlayClassName={overlayClass}
                className='CatalogModal'
              >
                <GraniteCarousel
                  granites={availableGranites.filter(
                    (granite) =>
                      !hideGranites.some(
                        (hideGranite) => hideGranite === granite.reference
                      )
                  )}
                  slideToShow={this.findGraniteIndex(
                    this.props.selected,
                    availableGranites
                  )}
                  ffooter={true}
                  ptext='Retour choix du granit'
                  previous={this.props.closeModal}
                  monument={monument}
                  next={(elt) => this.props.goNext(elt)}
                  ntext='Choisir ce granit'
                />
              </Modal>
            )}
          </div>
        </ModalButton>
        <ConfirmModal
          isOpen={this.state.showConfirm}
          className='ModalElemConfirm'
          centered={true}
          fade={true}
        >
          <div className='ElemConfirm'>
            <div class='header p-4 modal-header'>
              <h2 class='modal-title'>Confirmez votre choix</h2>
              <button
                type='button'
                class='close'
                aria-label='Close'
                onClick={() => {
                  this.onClickDisableGranite(this.state._granite, 'non')
                }}
              >
                <i class='icon material-icons'></i>
              </button>
            </div>

            <div class='content p-4 py-5 modal-body'>
              Le choix de ce granit va entraîner la suppression de certains
              accessoires.
              <br />
              Cliquez sur CONTINUER pour le choisir quand même.
            </div>

            <div class='p-4 modal-footer'>
              <span
                className='cancel'
                onClick={() => {
                  this.onClickDisableGranite(this.state._granite, 'non')
                }}
              >
                Annuler
              </span>

              <span
                className='confirm'
                onClick={() => {
                  this.onClickDisableGranite(this.state._granite, 'oui')
                }}
              >
                Continuer
              </span>
            </div>
          </div>
        </ConfirmModal>
      </div>
    )
  }
}

export const GlobalGranitePicker = connect(
  (state) => ({
    configuration: state.configurator.current.configuration,
    granites: state.configurator.current.options.granites,
    matchcolors: state.configurator.current.options.matchcolors,
    accessories: state.configurator.current.accessories,
    headstones: state.configurator.current.headstones,
    adminConfig: state.adminConfig.config,
    isFamily: Boolean(state.user.family)
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(GlobalGranitePickerComponent)
